import React from 'react';
import {  useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import {SET_SUBMIT_CONTACT_MESSAGE} from '../../actions/types';
import './Loading.css';

function ContactSubmited(){
    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);
    if(appUI.submitContactMessage === 'success'){
    return(
        <div className="loading-wrapper ">
             <div className="aligner-item--top">
               
                <span className="d-flex justify-content-center "><h2 className="fw-light text-center">Děkujeme za Vaši zprávu.</h2></span>
                <hr/>
                <span className="d-flex justify-content-center "><p className="lead text-center fw-light">Brzy se Vám ozveme.</p></span><br/>
                <span className="d-flex justify-content-center "><p className="text-center fw-light">Pokud máte další přání, neváhejte nás kontaktovat na přímo:</p></span><br/>
                <span className="d-flex justify-content-center "><p className="text-center fw-light">Tel: <a className="contact-href fw-bold" href="tel:+420727924945">+420 727 924 945</a> </p></span><br/>
                <span className="d-flex justify-content-center "><p className="text-center fw-light">E-mail:  <a className="contact-href fw-bold" href="mailto:zikmunda.reality@gmail.com">zikmunda.reality@gmail.com </a> </p></span><br/>
                <span className="d-flex justify-content-center">
                <Button className="fw-light shadow-sm" variant="outline-dark" size="lg" 
                onClick={() => {
                    dispatch({type: SET_SUBMIT_CONTACT_MESSAGE, payload: ''});
                }}
                >Rozumím</Button>
                </span>
             </div>
           
        </div>
    )
            
    }
    else if(appUI.submitContactMessage === 'error'){
    return(
        <div className="loading-wrapper ">
             <div className="aligner-item--top">
                <span className="d-flex justify-content-center "><h2 className="fw-light text-center">Děkujeme za Vaši zprávu.</h2></span>
                <hr/>
                <span className="d-flex justify-content-center "><p className="lead text-center fw-light text-danger">Ale bohužel došlo k chybě při jejím zpracování.</p></span><br/>
                <span className="d-flex justify-content-center "><p className="text-center fw-light">Poprosíme Vás abyste si zkontrolovali připojení k internetu a zkusili zprávu poslat znovu nebo nás kontaktovali na přímo:</p></span><br/> 
                <span className="d-flex justify-content-center "><p className="text-center fw-light">Tel: <a className="contact-href fw-bold" href="tel:420727924945">+420 727 924 945</a> </p></span><br/>
                <span className="d-flex justify-content-center "><p className="text-center fw-light">E-mail:  <a className="contact-href fw-bold" href="mailto:zikmunda.reality@gmail.com">zikmunda.reality@gmail.com</a> </p></span><br/>
                <span className="d-flex justify-content-center">
                <Button className="fw-light shadow-sm" variant="outline-dark" size="lg" 
                onClick={() => {
                    dispatch({type: SET_SUBMIT_CONTACT_MESSAGE, payload: ''});
                }}
                >Rozumím</Button>
                </span>
             </div>
           
        </div>
    )
    }
    else return null;
}

export default ContactSubmited;